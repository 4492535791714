export const BtnLogin = () => import('../../components/BtnLogin.vue' /* webpackChunkName: "components/btn-login" */).then(c => wrapFunctional(c.default || c))
export const BtnPrimary = () => import('../../components/BtnPrimary.vue' /* webpackChunkName: "components/btn-primary" */).then(c => wrapFunctional(c.default || c))
export const BtnSupport = () => import('../../components/BtnSupport.vue' /* webpackChunkName: "components/btn-support" */).then(c => wrapFunctional(c.default || c))
export const ContentView = () => import('../../components/ContentView.vue' /* webpackChunkName: "components/content-view" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormPassanger = () => import('../../components/FormPassanger.vue' /* webpackChunkName: "components/form-passanger" */).then(c => wrapFunctional(c.default || c))
export const FormPassangerReis = () => import('../../components/FormPassangerReis.vue' /* webpackChunkName: "components/form-passanger-reis" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const SearchLine = () => import('../../components/SearchLine.vue' /* webpackChunkName: "components/search-line" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const Backtop = () => import('../../components/backtop.vue' /* webpackChunkName: "components/backtop" */).then(c => wrapFunctional(c.default || c))
export const CurrencyPopover = () => import('../../components/currency-popover.vue' /* webpackChunkName: "components/currency-popover" */).then(c => wrapFunctional(c.default || c))
export const DataDeparture = () => import('../../components/data-departure.vue' /* webpackChunkName: "components/data-departure" */).then(c => wrapFunctional(c.default || c))
export const LanguagePopover = () => import('../../components/language-popover.vue' /* webpackChunkName: "components/language-popover" */).then(c => wrapFunctional(c.default || c))
export const LineData = () => import('../../components/line-data.vue' /* webpackChunkName: "components/line-data" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const MainReview = () => import('../../components/main/review.vue' /* webpackChunkName: "components/main-review" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/map/map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const DepartureRow = () => import('../../components/departure/DepartureRow.vue' /* webpackChunkName: "components/departure-row" */).then(c => wrapFunctional(c.default || c))
export const AccountRouteInfo = () => import('../../components/account/RouteInfo.vue' /* webpackChunkName: "components/account-route-info" */).then(c => wrapFunctional(c.default || c))
export const AccountHistoryItem = () => import('../../components/account/history-item.vue' /* webpackChunkName: "components/account-history-item" */).then(c => wrapFunctional(c.default || c))
export const SearchResult = () => import('../../components/search/Result.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c))
export const SearchInfo = () => import('../../components/search/info.vue' /* webpackChunkName: "components/search-info" */).then(c => wrapFunctional(c.default || c))
export const RouteData = () => import('../../components/route/RouteData.vue' /* webpackChunkName: "components/route-data" */).then(c => wrapFunctional(c.default || c))
export const RouteDataDetail = () => import('../../components/route/RouteDataDetail.vue' /* webpackChunkName: "components/route-data-detail" */).then(c => wrapFunctional(c.default || c))
export const RouteDataRow = () => import('../../components/route/RouteDataRow.vue' /* webpackChunkName: "components/route-data-row" */).then(c => wrapFunctional(c.default || c))
export const RouteFullData = () => import('../../components/route/RouteFullData.vue' /* webpackChunkName: "components/route-full-data" */).then(c => wrapFunctional(c.default || c))
export const UtilAllRoute = () => import('../../components/util/AllRoute.vue' /* webpackChunkName: "components/util-all-route" */).then(c => wrapFunctional(c.default || c))
export const UtilBreadCrumb = () => import('../../components/util/BreadCrumb.vue' /* webpackChunkName: "components/util-bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const UtilFaqCityFromTo = () => import('../../components/util/FaqCityFromTo.vue' /* webpackChunkName: "components/util-faq-city-from-to" */).then(c => wrapFunctional(c.default || c))
export const UtilIcon = () => import('../../components/util/Icon.vue' /* webpackChunkName: "components/util-icon" */).then(c => wrapFunctional(c.default || c))
export const UtilPopRoute = () => import('../../components/util/PopRoute.vue' /* webpackChunkName: "components/util-pop-route" */).then(c => wrapFunctional(c.default || c))
export const UtilPopRouteDate = () => import('../../components/util/PopRouteDate.vue' /* webpackChunkName: "components/util-pop-route-date" */).then(c => wrapFunctional(c.default || c))
export const UtilPopRouteDateDan = () => import('../../components/util/PopRouteDateDan.vue' /* webpackChunkName: "components/util-pop-route-date-dan" */).then(c => wrapFunctional(c.default || c))
export const UtilPopRouteMain = () => import('../../components/util/PopRouteMain.vue' /* webpackChunkName: "components/util-pop-route-main" */).then(c => wrapFunctional(c.default || c))
export const UtilReviewBlock = () => import('../../components/util/ReviewBlock.vue' /* webpackChunkName: "components/util-review-block" */).then(c => wrapFunctional(c.default || c))
export const UtilScheduleCityFromTo = () => import('../../components/util/ScheduleCityFromTo.vue' /* webpackChunkName: "components/util-schedule-city-from-to" */).then(c => wrapFunctional(c.default || c))
export const UtilTextualBlockCityA = () => import('../../components/util/TextualBlockCityA.vue' /* webpackChunkName: "components/util-textual-block-city-a" */).then(c => wrapFunctional(c.default || c))
export const UtilTextualBlockCityB = () => import('../../components/util/TextualBlockCityB.vue' /* webpackChunkName: "components/util-textual-block-city-b" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
